@import-normalize;

@font-face {
	font-family: 'Atkinson Hyperlegible';
	src: url("./fonts/Atkinson-Hyperlegible-Regular-102a.woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Atkinson Hyperlegible';
	src: url("./fonts/Atkinson-Hyperlegible-Bold-102a.woff2");
	font-weight: bold;
	font-style: normal;
}
